import React from 'react';

export default function TinfoffFields({ fullName, price, description }) {
  const priceInKopeks = price * 100;
  return (
    <>
      <input type="hidden" name="description" value={description} />
      <input
        type="hidden"
        name="receipt"
        value={`${JSON.stringify({
          Taxation: 'osn',
          Items: [
            {
              Name: description,
              Price: priceInKopeks,
              Quantity: 1,
              Amount: priceInKopeks,
              PaymentMethod: 'prepayment',
              PaymentObject: 'service',
              Tax: 'none',
            },
          ],
        })}`}
      />
      <input
        type="hidden"
        name="DATA"
        value={JSON.stringify({ Name: fullName })}
      />
      <input type="hidden" name="amount" value={price} />
      <input type="hidden" name="terminalkey" value="1620223484819" />
    </>
  );
}
