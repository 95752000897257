import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, IconButton } from 'gatsby-theme-material-ui';
import QRCodeBlock from '../../shared/QRCodeBlock/QRCodeBlock';
import PayemntDetails from '../../shared/PayemntDetails/PayemntDetails';
import styles from './styles';
import { CloseIcon } from '../../kit/icons/CloseIcon';

export default function QRCodePaymentModal({
  qrCodePrice,
  actualPrice,
  closeHandler,
  course,
  groupCity,
  email,
}) {
  const [infoBlockIsShowed, setInfoBlockIsShowed] = useState(false);
  useEffect(() => {}, []);
  return (
    <Box
      sx={{
        ...styles.qrCodeContainer,
        backgroundColor: infoBlockIsShowed ? '#54454f' : 'white',
      }}
    >
      {!infoBlockIsShowed && (
        <>
          <Box sx={{ ...styles.qrCodeBg, ...styles.qrCodeBg1 }} />
          <Box sx={{ ...styles.qrCodeBg, ...styles.qrCodeBg2 }} />
          <Box sx={styles.qrCodeBox}>
            <QRCodeBlock
              price={qrCodePrice}
              fullPrice={actualPrice}
              course={course}
              groupCity={groupCity}
              email={email}
            />
            <PayemntDetails />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: 3,
                mb: { xs: 8, lg: 4 },
              }}
            >
              <Button
                size={isMobile ? 'smallest' : 'medium'}
                variant="outlined"
                sx={{ fontSize: 32, lineHeight: 1, borderRadius: 4 }}
                onClick={closeHandler}
              >
                <ArrowBackIcon
                  sx={{
                    fill: 'currentColor',
                    width: 24,
                    height: 24,
                  }}
                />
              </Button>
              <Button
                variant="kitPrimary"
                onClick={() => setInfoBlockIsShowed(true)}
              >
                готово
              </Button>
            </Box>
          </Box>
        </>
      )}
      {infoBlockIsShowed && (
        <Box
          sx={{
            m: 'auto',
            mt: { xs: 'calc(20vh)', lg: 'calc(35vh)' },
            width: { xs: 296, lg: 439 },
            height: { xs: 178, lg: 255 },
            borderRadius: 4,
            backgroundColor: 'kit.background.gray',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            p: (theme) => ({
              xs: theme.spacing(6, 2, 3),
              lg: theme.spacing(7, 6, 5),
            }),
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 8, right: 8 }}
            onClick={closeHandler}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: 343,
            }}
          >
            <Typography
              variant="desktopH4"
              sx={{ fontSize: { xs: 16, lg: 22 }, textAlign: 'center' }}
            >
              После успешной оплаты вам на почту придет письмо с чеком в течение
              2 рабочих дней
            </Typography>
            <svg
              style={{ marginTop: 'auto' }}
              width="57"
              height="56"
              viewBox="0 0 57 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.5 35.3327L19.5606 56L56.5 17H48L19.5606 47L8 34.5L0.5 35.3327Z"
                fill="#14C466"
              />
            </svg>
          </Box>
        </Box>
      )}
    </Box>
  );
}
