export default {
  seo: {
    title:
      'Выберите удобный для вас вариант оплаты. Предоставляется скидка при единовременном платеже. При оплате в рассрочку, вы не платите проценты банку, их мы берем на себя.',
    meta: [
      {
        property: 'description',
        name: 'description',
        content:
          'Воспользуйтесь комфортной рассрочкой или оплатите обучение сразу и со скидкой. После получения работы в роли разработчика окупаемость обучения достигается за 2-3 месяца.',
      },
      {
        property: 'propertywords',
        name: '',
        content: '',
      },
      {
        property: 'og:site_name',
        name: null,
        content: 'Эльбрус Буткемп',
      },
      {
        property: 'og:title',
        name: '',
        content:
          'Варианты оплаты курсов программирования в Эльбрус Буткемп: рассрочка и единовременный платеж со скидкой',
      },
      { property: 'og:locale', name: null, content: 'ru_RU' },
      {
        property: 'og:description',
        name: 'description',
        content:
          'Воспользуйтесь комфортной рассрочкой или оплатите обучение сразу и со скидкой. После получения работы в роли разработчика окупаемость обучения достигается за 2-3 месяца.',
      },
      {
        property: 'og:type',
        name: '',
        content: 'product',
      },
      {
        property: 'og:site_name',
        name: '',
        content: 'Школа программирования Эльбрус Буткемп в Москве',
      },
      {
        property: 'og:image',
        name: '',
        content:
          'https://elbrusboot.camp/static/ee7e4ac34968829de94b6453c888807b/74209/slide-6.jpg',
      },
      { property: 'fb:app_id', name: null, content: '581051622277135' },
      { property: 'twitter:card', name: null, content: 'summary_large_image' },
      {
        property: 'twitter:site',
        name: null,
        content: 'https://twitter.com/elbrus_bootcamp',
      },
      {
        property: 'twitter:image:src',
        name: null,
        content:
          'https://elbrusboot.camp/static/ee7e4ac34968829de94b6453c888807b/74209/slide-6.jpg',
      },
    ],
  },
};
