import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import BankCardIcon from '../../kit/icons/BankCardIcon';
import QRCodeIcon from '../../kit/icons/QRCodeIcon';
import { QRCODE_DISCOUNT } from '../../widgets/PaymentPageBlock/constants';

const PaymentMethodButton = ({ children, label = false, active, onClick }) => {
  const labelWidth = 70;
  const labelHeight = 33;
  const transformLabelX = 35;
  const transformLabelY = 36;

  return (
    <Button
      size="large"
      variant="outlined"
      onClick={onClick}
      sx={(theme) => ({
        borderRadius: 3,
        borderColor: active ? 'inherit' : 'lightgray',
        padding: theme.spacing(2, 4),
        position: 'relative',
        mt: `${(labelHeight * transformLabelY) / 100 - 2}px`,
        mr: `${(labelWidth * transformLabelX) / 100 - 2}px`,
      })}
    >
      {label && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            transform: `translate(${transformLabelX}%, -${transformLabelY}%)`,
            width: labelWidth,
            height: labelHeight,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#29EDFF',
            borderRadius: 4,
          }}
        >
          <Typography variant="desktopP3" color={'kit.text.h'} fontWeight={700}>
            {`-${QRCODE_DISCOUNT * 100}%`}
          </Typography>
        </Box>
      )}
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        {children}
      </Box>
    </Button>
  );
};
const methodsList = [
  {
    text: 'Картой онлайн',
    image: <BankCardIcon />,
    lable: false,
  },
  {
    text: 'QR−код или реквизиты',
    image: <QRCodeIcon />,
    lable: true,
  },
];
export default function PaymentMethodsBlock({
  active,
  setActive,
  noDiscount = false,
}) {
  return (
    <Box
      sx={{
        mt: 1.6,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}
    >
      {methodsList.map((method, index) => (
        <PaymentMethodButton
          key={index}
          active={index === active}
          onClick={() => setActive(index)}
          label={!noDiscount && method.lable}
        >
          {method.image}
          {method.text}
        </PaymentMethodButton>
      ))}
    </Box>
  );
}

//
