const styles = {
  form: {
    width: { xs: '100%', xl: 1110 },
    height: { xs: '100%', xl: 700 },
    display: 'flex',
    columnGap: { xs: 5, xl: '188px' },
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  dropDown: {
    padding: '0 !important',
  },
  inputTelephone: {
    fontFamily: 'Inter',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.02em',
    color: '#666666',
    width: '100%',
    borderRadius: '0',
    border: 'none',
    borderBottom: '1px solid #000',
    outline: 'none',
    backgroundColor: 'transparent',
    height: '48px',

    '&:focus': {
      color: '#000',
      outline: 'none',
      boxShadow: 'none',
      borderBottom: '1px solid #FABA5A',
    },

    '&.danger': {
      borderColor: '1px solid #FF6363',
    },
  },
  phoneWrapper: {
    position: 'relative',
    mt: 3,
    '& .react-tel-input .flag-dropdown': {
      backgroundColor: 'transparent',
      border: 'none',
    },
    '& .react-tel-input .selected-flag': {
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .react-tel-input .selected-flag:hover': {
      backgroundColor: 'transparent',
    },
    '& .react-tel-input .flag-dropdown.open .selected-flag': {
      backgroundColor: 'transparent',
    },
    '& .react-tel-input .selected-flag:focus::before': {
      boxShadow: 'none',
      border: 'none',
    },
    '& .react-tel-input .selected-flag.open::before ': {
      boxShadow: 'none',
      border: 'none',
    },
    '& .react-tel-input .form-control:focus': {
      boxShadow: 'unset',
      border: 'none',
      borderBottom: '1px solid #FABA5A !important',
    },
    '& .react-tel-input .special-label': {
      display: 'block',
      color: 'kit.text.secondary',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '21px',
      letterSpacing: '0.03em',
      textAlign: 'left',
      position: 'absolute',
      top: '-5px',
      letterSpacing: '0.00938em',
      left: 0,
      background: 'none',
      padding: 0,
    },
  },
  qrCodeContainer: {
    width: '100vw',
    height: '100vh',
    overflowX: 'hidden',
    overflowY: 'scroll',
    position: 'relative',
    pt: { xs: 10, lg: 0 },
    display: { xs: 'block', md: 'flex' },
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  qrCodeBg: {
    position: 'absolute',
    border: 'solid 10px #29EDFF',
    width: '734px',
    height: '734px',
    flexShrink: '0',

    borderRadius: '734px',
  },
  qrCodeBg1: {
    left: { xs: -300, lg: 0 },
    bottom: { xs: -300, lg: 0 },

    background:
      'linear-gradient(312deg, rgba(149, 111, 255, 0.60) -9.97%, rgba(41, 237, 255, 0.60) 100.46%)',
    filter: { xs: 'blur(150px)', lg: 'blur(250px)' },
  },
  qrCodeBg2: {
    top: -200,
    right: -200,
    background:
      'linear-gradient(325deg, rgba(252, 111, 255, 0.60) 33.14%, rgba(41, 237, 255, 0.60) 100%)',
    filter: { xs: 'blur(150px)', lg: 'blur(250px)' },
  },
  qrCodeBox: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: { xs: 3, lg: 5 },
    px: 2,
  },
  errorMessage: {
    textAlign: 'left',
    width: '100%',
    display: 'inline-block',
    position: 'absolute',
    bottom: 0,
    transform: 'translateY(100%)',
    paddingTop: '4px',
    fontSize: '12px',
    lineHeight: '21px',
    left: 0,
    color: 'kit.text.error',
    fontFamily: 'Inter',
    letterSpacing: '0.03em',
  },
};

export default styles;
