import { SvgIcon } from '@mui/material';
import React from 'react';

export default function QRCodeIcon() {
  return (
    <SvgIcon
      sx={{ width: 24, height: 24 }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.71429 9.42855H7.71427C8.66106 9.42855 9.42855 8.66106 9.42855 7.71427V1.71429C9.42855 0.767491 8.66106 0 7.71427 0H1.71429C0.767491 0 0 0.767491 0 1.71429V7.71427C0 8.66106 0.767491 9.42855 1.71429 9.42855ZM1.71429 1.71429H7.71427V7.71427H1.71429V1.71429Z"
        fill="black"
      />
      <path
        d="M22.2866 0H16.2866C15.3398 0 14.5723 0.767491 14.5723 1.71429V7.71427C14.5723 8.66106 15.3398 9.42855 16.2866 9.42855H22.2866C23.2334 9.42855 24.0009 8.66106 24.0009 7.71427V1.71429C24.0009 0.767491 23.2334 0 22.2866 0ZM22.2866 7.71427H16.2866V1.71429H22.2866V7.71427Z"
        fill="black"
      />
      <path
        d="M7.71427 14.5703H1.71429C0.767491 14.5703 0 15.3378 0 16.2846V22.2846C0 23.2314 0.767491 23.9989 1.71429 23.9989H7.71427C8.66106 23.9989 9.42855 23.2314 9.42855 22.2846V16.2846C9.42855 15.3378 8.66106 14.5703 7.71427 14.5703ZM7.71427 22.2846H1.71429V16.2846H7.71427V22.2846Z"
        fill="black"
      />
      <path
        d="M0.857122 12.8571H12C12.4734 12.8571 12.8571 12.4734 12.8571 12V0.857122C12.8571 0.383745 12.4734 0 12 0C11.5266 0 11.1428 0.383745 11.1428 0.857122V11.1428H0.857122C0.383745 11.1428 0 11.5266 0 12C0 12.4734 0.383745 12.8571 0.857122 12.8571Z"
        fill="black"
      />
      <path
        d="M16.2854 16.2835V11.9978C16.2854 11.5244 15.9017 11.1406 15.4283 11.1406C14.9549 11.1406 14.5711 11.5244 14.5711 11.9978V15.4264H11.9997C11.5264 15.4264 11.1426 15.8101 11.1426 16.2835V22.2835C11.1426 22.7569 11.5264 23.1407 11.9997 23.1407C12.4731 23.1407 12.8569 22.7569 12.8569 22.2835V17.1406H15.4283C15.9017 17.1406 16.2854 16.7569 16.2854 16.2835Z"
        fill="black"
      />
      <path
        d="M22.2866 18.8594H15.4294C14.9561 18.8594 14.5723 19.2431 14.5723 19.7165C14.5723 20.1899 14.9561 20.5737 15.4294 20.5737H17.1437V22.2879C17.1437 22.7613 17.5275 23.1451 18.0008 23.1451C18.4743 23.1451 18.858 22.7613 18.858 22.2879V20.5737H21.4294V22.2879C21.4294 22.7613 21.8132 23.1451 22.2866 23.1451C22.7599 23.1451 23.1437 22.7613 23.1437 22.2879V19.7165C23.1437 19.2431 22.7599 18.8594 22.2866 18.8594Z"
        fill="black"
      />
      <path
        d="M23.1429 12.8549C23.6163 12.8549 24 12.4712 24 11.9978C24 11.5244 23.6163 11.1406 23.1429 11.1406H18.8572C18.3838 11.1406 18 11.5244 18 11.9978V16.2835C18 16.7569 18.3838 17.1406 18.8572 17.1406H23.1429C23.6163 17.1406 24 16.7569 24 16.2835C24 15.8101 23.6163 15.4264 23.1429 15.4264H19.7143V12.8549H23.1429Z"
        fill="black"
      />
    </SvgIcon>
  );
}
