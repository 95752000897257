import { Box, Typography } from '@mui/material';
import React from 'react';

export const paymentDetails = {
  Name: 'ООО "ЭЛЬБРУС БУТКЕМП"',
  PayeeINN: '7736332093',
  KPP: '772501001',
  PersonalAcc: '40702810600000285464',
  BankName: 'ПАО "ПРОМСВЯЗЬБАНК" г. Москва',
  BIC: '044525555',
  CorrespAcc: '30101810400000000555',
  BankINN: '7710140679',
};

export default function PayemntDetails() {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.kit.background.gray,
        height: 439,
        width: 424,
        borderRadius: '16px',
        p: theme.spacing(3, 4, 4),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: 2.3,
      })}
    >
      <Typography
        variant="desktopH4"
        color={'kit.text.main'}
        textAlign={'center'}
        width={'100%'}
        // mb={2}
      >
        Реквизиты компании
      </Typography>
      <Typography
        variant="desktopP2"
        color="kit.text.secondary"
        // sx={styles.content}
        component="div"
      >
        <Typography>{paymentDetails.Name}</Typography>
        <Typography>
          ИНН/КПП: {paymentDetails.BankINN}/{paymentDetails.KPP}
        </Typography>
      </Typography>
      <Typography variant="desktopP2" color="kit.text.secondary">
        ЮР./ПОЧТОВЫЙ АДРЕС: 115419, г. Москва, ул. Орджоникидзе, д. 11, стр. 10,
        ком. 1
      </Typography>
      <Typography variant="desktopP2" color="kit.text.secondary">
        РАСЧЕТНЫЙ СЧЕТ: {paymentDetails.PersonalAcc}
      </Typography>
      <Typography variant="desktopP2" color="kit.text.secondary">
        КОРР. СЧЕТ: {paymentDetails.CorrespAcc}
      </Typography>

      <Typography variant="desktopP2" color="kit.text.secondary">
        БИК: {paymentDetails.BIC}
      </Typography>
      <Typography variant="desktopP2" color="kit.text.secondary">
        НАИМЕНОВАНИЕ БАНКА: {paymentDetails.BankName}
      </Typography>
      {/* <Typography variant="desktopP2" color="kit.text.secondary">
        ИНН БАНКА: 7710140679
      </Typography> */}
    </Box>
  );
}
