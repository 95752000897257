import React, { useEffect, useLayoutEffect, useState } from 'react';
import { sendYandexMetrikaEvent } from '../helpers/yandexMetrika';
import Layout from '../shared/Layout/Layout';
import data from '../pages-fsd/PaymentPage/model/payment.data';
import PaymentPageBlock from '../widgets/PaymentPageBlock/PaymentPageBlock';
import { getPrices } from '../api/strapi';
import HeadMaker from '../HeadMaker';

export function Head(props) {
  return <HeadMaker {...props} seo={data.seo} />;
}

const mainData = {
  leadPage: 'JS Online_Оплата',
  title: 'Оплата',
};

function PaymentPage() {
  const [prices, setPrices] = useState([]);
  useEffect(() => {
    const ac = new AbortController();
    sendYandexMetrikaEvent('newPaymentPageView');
    getPrices(ac).then(setPrices);
  }, []);
  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js';
    script.async = true;
    document.body.insertBefore(script, document.body.firstChild);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <Layout leadPage="JS Online_Оплата">
      <PaymentPageBlock prices={prices} />
    </Layout>
  );
}

export default PaymentPage;
