import { SvgIcon } from '@mui/material';
import React from 'react';

export default function BankCardIcon() {
  return (
    <SvgIcon
      sx={{ width: 29, height: 24 }}
      viewBox="0 0 29 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.40909 17.4531C8.907 17.4531 8.5 17.9415 8.5 18.544C8.5 19.1465 8.907 19.6349 9.40909 19.6349H13.0455C13.5475 19.6349 13.9545 19.1465 13.9545 18.544C13.9545 17.9415 13.5475 17.4531 13.0455 17.4531H9.40909Z"
        fill="#111111"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.863281 5.53846C0.863281 2.47966 3.30536 0 6.31783 0H19.0451C21.5065 0 23.5871 1.65543 24.266 3.92958C26.5056 4.61889 28.136 6.73149 28.136 9.23077V18.4615C28.136 21.5203 25.6939 24 22.6815 24H9.95419C7.49278 24 5.41219 22.3446 4.73333 20.0704C2.49363 19.3811 0.863281 17.2685 0.863281 14.7692V5.53846ZM22.6815 5.53846C24.6898 5.53846 26.3178 7.19155 26.3178 9.23077H6.31783C6.31783 7.19155 7.94587 5.53846 9.95419 5.53846H22.6815ZM26.3178 11.0769H6.31783V18.4615C6.31783 20.5008 7.94587 22.1538 9.95419 22.1538H22.6815C24.6898 22.1538 26.3178 20.5008 26.3178 18.4615V11.0769ZM4.49964 17.9676V9.23077C4.49964 6.17197 6.94174 3.69231 9.95419 3.69231H22.195C21.5662 2.58868 20.3911 1.84615 19.0451 1.84615H6.31783C4.30952 1.84615 2.68146 3.49925 2.68146 5.53846V14.7692C2.68146 16.1359 3.41273 17.3292 4.49964 17.9676Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
