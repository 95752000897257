import { Box, Typography } from '@mui/material';
import React from 'react';
import QRCodeComponent from '../QRCodeComponent/QRCodeComponent';
import MailIcon from '../MailIcon/MailIcon';
const COURSES = {
  JS: 'Веб-разработка',
  JS_BASICS: 'Пре-курс JavaScript',
  DS: 'Data Science',
  DS_BASICS: 'Пре-курс Data Science',
  DESIGN: 'UX/UI Дизайн',
  DESIGN_BASICS: 'Пре-курс UX/UI Дизайн',
};
const LOCATIONS = {
  MSK: 'Москва',
  MSK_ENG: 'Moscow', // Have to be handled by i18n engine
  SPB: 'Санкт-Петербург',
  ONLINE: 'Онлайн',
  KZN: 'Казань',
  IST: 'Стамбул',
  EVN: 'Ереван',
  PART_TIME_ONLINE: 'Парт-тайм Онлайн',
};
export default function QRCodeBlock({
  price,
  fullPrice,
  course,
  groupCity,
  isPrepayment,
  email,
}) {
  const purpose = `${COURSES[course.toUpperCase()]} ${
    LOCATIONS[groupCity.toUpperCase()]
  }${isPrepayment ? ' (предоплата)' : ''} от ${email}`;
  const priseInkopecks = Math.round((price || fullPrice) * 100);

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.kit.background.gray,
        height: 439,
        width: { xs: '100vw', lg: 424 },
        borderRadius: '16px',
        p: theme.spacing(3, 2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.up('xl')]: {
          p: theme.spacing(3, 4, 4),
        },
      })}
    >
      <Typography variant="desktopH4">QR-код для оплаты</Typography>
      <Box mt={1}>
        <Typography
          component={'span'}
          variant="desktopH3"
          color={'kit.text.main'}
          letterSpacing={-1.75}
          mt={1}
        >
          {(price || fullPrice).toLocaleString('ru-RU')} ₽{!!price && '/'}
        </Typography>
        {!!price && (
          <Typography
            component={'span'}
            variant="desktopH4"
            sx={{
              textDecoration: 'line-through',
              mt: 1,
              letterSpacing: -1.75,
              color: 'kit.text.secondary',
            }}
          >
            {fullPrice.toLocaleString('ru-RU')} ₽
          </Typography>
        )}
      </Box>
      <Typography variant="desktopP3" color={'kit.text.secondary'} mt={1}>
        Отсканируйте QR-код в приложении банка
      </Typography>
      <Box
        sx={{
          mt: 3,
          width: 160,
          height: 160,
          borderRadius: 5,
          p: 2,
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <QRCodeComponent sum={priseInkopecks} purpose={purpose} />
      </Box>
      <Box sx={{ mt: 3, display: 'flex', gap: 1.5, maxWidth: 375, mx: -1 }}>
        <MailIcon />
        <Typography variant="desktopP3" color={'kit.text.secondary'} mt={1}>
          После успешной оплаты вам на почту придет письмо с чеком в течение 2
          рабочих дней
        </Typography>
      </Box>
    </Box>
  );
}
