const excludeProducts = ['msk_ds'];
const QRCODE_DISCOUNT = 0.005;

const streamName = {
  msk_js: 'Веб-разработчик / Москва',
  spb_js: 'Веб-разработчик / Санкт-Петербург',
  online_js: 'Веб-разработчик / Онлайн / Фулл-тайм',
  part_time_online_js: 'Веб-разработчик / Онлайн / Парт-тайм',
  prepare_js: 'Основы JavaScriprt / Онлайн',
  msk_ds: 'Data Science / Москва',
  prepare_ds: 'Основы Python / Онлайн',
  online_ds: 'Data Science / Онлайн',
  online_ux: 'UX/UI дизайн / Онлайн',
  prepare_design: 'Погружение в Figma / Онлайн',
};
const programsNames = {
  js: 'Веб-разработка',
  ds: 'Data Science',
  design: 'UX/UI дизайн',
  js_basics: 'Основы Javascript',
  ds_basics: 'Основы Python',
  design_basics: 'Погружение в Figma',
};
const programsOrder = [
  'js',
  'ds',
  'design',
  'js_basics',
  'ds_basics',
  'design_basics',
];
const locationsNames = {
  msk: 'Офлайн / Москва',
  spb: 'Офлайн / Санкт-Петербург',
  online: 'Онлайн / Фулл-тайм',
  part_time_online: 'Онлайн / Парт-тайм',
};
const locationsOrder = ['online', 'msk', 'spb', 'part_time_online'];

export {
  excludeProducts,
  QRCODE_DISCOUNT,
  streamName,
  programsNames,
  programsOrder,
  locationsNames,
  locationsOrder,
};
