import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { paymentDetails } from '../PayemntDetails/PayemntDetails';

function generateDynamicPaymentString(dataObject) {
  const keyValuePairs = Object.entries(dataObject).map(
    ([key, value]) => `${key}=${value}`,
  );
  return `ST00012|${keyValuePairs.join('|')}`;
}

function QRCodeComponent({ sum, purpose }) {
  // const paymentDetails = {
  //   Name: 'ООО "ЭЛЬБРУС БУТКЕМП"',
  //   PayeeINN: '7736332093',
  //   KPP: '772501001',
  //   PersonalAcc: '40702810600000285464',
  //   BankName: 'ПАО "ПРОМСВЯЗЬБАНК" г. Москва',
  //   BIC: '044525555',
  //   CorrespAcc: '30101810400000000555',
  //   BankINN: '7710140679',
  //   Sum: sum,
  //   Purpose: purpose,
  // };

  return (
    <QRCodeSVG
      size={140}
      value={generateDynamicPaymentString({
        ...paymentDetails,
        Sum: sum,
        Purpose: purpose,
      })}
    />
  );
}

export default QRCodeComponent;
