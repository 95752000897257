import React from 'react';

export default function PercentIcon() {
  return (
    <svg
      width="47"
      height="45"
      viewBox="0 0 47 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_15468_108442)">
        <rect x="0.973633" width="46" height="44.04" rx="12" fill="white" />
        <rect
          x="1.20399"
          y="0.230354"
          width="45.5393"
          height="43.5793"
          rx="11.7696"
          stroke="white"
          strokeOpacity="0.2"
          strokeWidth="0.460708"
        />
        <path
          d="M21.0096 20.408C20.1136 20.408 19.2923 20.2053 18.5456 19.8C17.799 19.3947 17.2016 18.84 16.7536 18.136C16.327 17.4107 16.1136 16.6 16.1136 15.704C16.1136 14.808 16.327 14.008 16.7536 13.304C17.2016 12.5787 17.799 12.0133 18.5456 11.608C19.2923 11.2027 20.1136 11 21.0096 11C21.9056 11 22.727 11.2027 23.4736 11.608C24.2203 12.0133 24.807 12.5787 25.2336 13.304C25.6816 14.008 25.9056 14.808 25.9056 15.704C25.9056 16.6 25.6816 17.4107 25.2336 18.136C24.807 18.84 24.2203 19.3947 23.4736 19.8C22.727 20.2053 21.9056 20.408 21.0096 20.408ZM21.0096 13.88C20.4763 13.88 20.039 14.0507 19.6976 14.392C19.3563 14.712 19.1856 15.1493 19.1856 15.704C19.1856 16.2587 19.3563 16.7067 19.6976 17.048C20.039 17.368 20.4763 17.528 21.0096 17.528C21.543 17.528 21.9803 17.368 22.3216 17.048C22.663 16.7067 22.8336 16.2587 22.8336 15.704C22.8336 15.1493 22.663 14.712 22.3216 14.392C21.9803 14.0507 21.543 13.88 21.0096 13.88ZM33.1376 20.088L16.5296 27.352L15.4736 24.952L32.0816 17.688L33.1376 20.088ZM27.6016 24.632C28.4976 24.632 29.319 24.8347 30.0656 25.24C30.8123 25.6453 31.399 26.2107 31.8256 26.936C32.2736 27.64 32.4976 28.44 32.4976 29.336C32.4976 30.232 32.2736 31.0427 31.8256 31.768C31.399 32.472 30.8123 33.0267 30.0656 33.432C29.319 33.8373 28.4976 34.04 27.6016 34.04C26.7056 34.04 25.8843 33.8373 25.1376 33.432C24.391 33.0267 23.7936 32.472 23.3456 31.768C22.919 31.0427 22.7056 30.232 22.7056 29.336C22.7056 28.44 22.919 27.64 23.3456 26.936C23.7936 26.2107 24.391 25.6453 25.1376 25.24C25.8843 24.8347 26.7056 24.632 27.6016 24.632ZM27.6016 31.16C28.135 31.16 28.5723 31 28.9136 30.68C29.255 30.3387 29.4256 29.8907 29.4256 29.336C29.4256 28.7813 29.255 28.344 28.9136 28.024C28.5723 27.6827 28.135 27.512 27.6016 27.512C27.0683 27.512 26.631 27.6827 26.2896 28.024C25.9483 28.344 25.7776 28.7813 25.7776 29.336C25.7776 29.8907 25.9483 30.3387 26.2896 30.68C26.631 31 27.0683 31.16 27.6016 31.16Z"
          fill="#5F37CC"
        />
        <path
          d="M20.3456 19.408C19.4496 19.408 18.6282 19.2053 17.8816 18.8C17.1349 18.3947 16.5376 17.84 16.0896 17.136C15.6629 16.4107 15.4496 15.6 15.4496 14.704C15.4496 13.808 15.6629 13.008 16.0896 12.304C16.5376 11.5787 17.1349 11.0133 17.8816 10.608C18.6282 10.2027 19.4496 10 20.3456 10C21.2416 10 22.0629 10.2027 22.8096 10.608C23.5562 11.0133 24.1429 11.5787 24.5696 12.304C25.0176 13.008 25.2416 13.808 25.2416 14.704C25.2416 15.6 25.0176 16.4107 24.5696 17.136C24.1429 17.84 23.5562 18.3947 22.8096 18.8C22.0629 19.2053 21.2416 19.408 20.3456 19.408ZM20.3456 12.88C19.8122 12.88 19.3749 13.0507 19.0336 13.392C18.6922 13.712 18.5216 14.1493 18.5216 14.704C18.5216 15.2587 18.6922 15.7067 19.0336 16.048C19.3749 16.368 19.8122 16.528 20.3456 16.528C20.8789 16.528 21.3162 16.368 21.6576 16.048C21.9989 15.7067 22.1696 15.2587 22.1696 14.704C22.1696 14.1493 21.9989 13.712 21.6576 13.392C21.3162 13.0507 20.8789 12.88 20.3456 12.88ZM32.4736 19.088L15.8656 26.352L14.8096 23.952L31.4176 16.688L32.4736 19.088ZM26.9376 23.632C27.8336 23.632 28.6549 23.8347 29.4016 24.24C30.1482 24.6453 30.7349 25.2107 31.1616 25.936C31.6096 26.64 31.8336 27.44 31.8336 28.336C31.8336 29.232 31.6096 30.0427 31.1616 30.768C30.7349 31.472 30.1482 32.0267 29.4016 32.432C28.6549 32.8373 27.8336 33.04 26.9376 33.04C26.0416 33.04 25.2202 32.8373 24.4736 32.432C23.7269 32.0267 23.1296 31.472 22.6816 30.768C22.2549 30.0427 22.0416 29.232 22.0416 28.336C22.0416 27.44 22.2549 26.64 22.6816 25.936C23.1296 25.2107 23.7269 24.6453 24.4736 24.24C25.2202 23.8347 26.0416 23.632 26.9376 23.632ZM26.9376 30.16C27.4709 30.16 27.9082 30 28.2496 29.68C28.5909 29.3387 28.7616 28.8907 28.7616 28.336C28.7616 27.7813 28.5909 27.344 28.2496 27.024C27.9082 26.6827 27.4709 26.512 26.9376 26.512C26.4042 26.512 25.9669 26.6827 25.6256 27.024C25.2842 27.344 25.1136 27.7813 25.1136 28.336C25.1136 28.8907 25.2842 29.3387 25.6256 29.68C25.9669 30 26.4042 30.16 26.9376 30.16Z"
          fill="#8560EC"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_15468_108442"
          x="-17.6625"
          y="-18.6361"
          width="83.2723"
          height="81.3113"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="9.31807" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_15468_108442"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_15468_108442"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
